<template>
  <div class="w_auto">
      <div class="resourceItem">
          <!-- 图片预览 -->
          <div v-if="chapterName=='jpg'||chapterName=='png'||chapterName=='jpeg'||chapterName=='pic'">
              <img :src="resourceList.resourceUrl" alt="">
          </div>  
           <!--  -->
          <div v-else-if="chapterName=='pdf'">
              <h2>{{resourceList.chapterName}}</h2>
              <div class="pdfPriview">
                <iframe :src='pdfUrl' type="application/x-google-chrome-pdf" frameborder="0" scrolling="auto" class="iframe"/>
              </div>
              <!-- <textarea width="100%" height="100%" frameborder="0" readonly class="TextView" :src="resourceList.resourceUrl"></textarea> -->
          </div>  
          <div v-else-if="chapterName=='txt'">
              <h2>{{resourceList.chapterName}}</h2>
              <iframe
                class="iframe"
                frameborder="0"
                :src="txtPre"
                />
          </div>
        <div v-else>
            <h2>{{resourceList.chapterName}}</h2>
            <iframe :src='wordUrl'  frameborder="0" scrolling="auto" class="iframe"/>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            token: localStorage.getItem("pc_token"),
            resourceList:{},
            chapterName:'',
            txtPre:'',
            pdfUrl:'',
            wordUrl:''
        }
    },
    mounted(){
        this.getMyCourseResource()
    },
    methods:{
        getMyCourseResource(){
            this.$api.getMyCourseResource({
                resourseId:this.$route.query.resourceId,
                token:this.token
            }).then(res => {
                if(res.code == 10000){
                    this.resourceList = res.data
                    let name = (res.data.chapterName).split('.')
                    this.chapterName = name[1]
                    this.pdfUrl = this.resourceList.resourceUrl + '#toolbar=0'
                    this.wordUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.resourceList.resourceUrl + '#toolbar=0'
                    this.txtPre = 'http://view.xdocin.com/xdoc?_xdoc=' + this.resourceList.resourceUrl + '#toolbar=0'
                    console.log(this.chapterName);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.resourceItem {
    padding: 50px 20px 30px;
    background: #fff;
    height: 100vh;
    overflow-y: auto;
}
.resourceItem img{
    width: 100%;
}
h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
    color: #000;
    font-weight: 500;
}
.TextView {
    border: none;
    resize: none;
    width: 100%;
    height: 100vh;
}
.pdfPriview{
        height: 100%;
        overflow: hidden;
    }
.iframe{
    width:100%; 
    height:100vh;
    border: 0;
}
</style>